<template>
  <div class="row" v-if="data.prix_germany">
    <div class="col-12 wrapper-box-fiche">   

        <!-- Informations générales start -->
        <div class="box-white-fiche" v-if="data.prix_germany.eu_number">
            <h3 class="text-orange">{{ $t('information generale') }}</h3>
            <table class="comparateur2 border-top-gray">
                <tr>
                    <td class="bold" width="50%"><strong> {{$t('Eu number')}} </strong></td>
                    <td width="50%"> {{data.prix_germany.eu_number}} </td>
                </tr>
            </table>
        </div>
        <!-- Informations générales end-->

        <!--Data PrixGermanyPrice start-->
        <div class="box-white-fiche" v-if="data.prix_germany.prix_germany_price.length > 0">
            <table class="comparateur2 border-top-gray">
                <thead>
                    <tr>
                        <th class="gray" width="50%">Price ex-factory (Ex. VAT)</th>
                        <th class="gray" width="50%">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, key) in data.prix_germany.prix_germany_price" :key="key">
                        <td> {{value['price'] ? value['price'] : '-' }}  </td>
                        <td> {{ value['date'] ? value['date'] : '-' }} </td>                          
                    </tr>
                </tbody>
            </table>
        </div>

        <!--Data PrixGermanyPrice end-->
    </div> 
</div>
</template>

<script>
export default {
    name : 'PrixGermany',
    computed : {
        data() {
            return this.$store.getters['detail/data']
        }
    }
}
</script>

<style>

</style>